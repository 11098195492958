import React from 'react';
import '../../App.css';
import AboutSection from '../AboutSection';

function About(props) {
	return (
		<>
			<AboutSection />
		</>
	);
}

export default About;